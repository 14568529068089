const $doc = $(document);
const $win = $(window);

const makeHash = (length) => {
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (var i = 0; i < length; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    return text;
};

const initDotDotDot = () => {
    $('.ddd').dotdotdot({ellipsis: '... ', wrap: 'letter', watch: true})
};


const alertForDisableLinks = () => {
    $('a[href="#"]').on('click', (e) => {
        e.preventDefault();
    })
};

const toggleMobileMenu = () => {
    const $body = $('body');
    $('#btnMobile').on('click', (e) => {
        const $this = $(e.currentTarget);
        $this.find('.burger').toggleClass('active');
        $body.toggleClass('mobile-on');
    });
    // $doc.on('click', (e)=>{
    //     const target = $(e.target);
    //     const isOpen = $('body').hasClass('mobile-on');
    //
    //     console.log('isOpen', isOpen);
    //     console.log('target', !target.hasClass('burger active'));
    //
    //     if( isOpen && !target.hasClass('burger active') || isOpen && !target.hasClass('main__menu-link')) {
    //         $('.burger').removeClass('active');
    //         $body.removeClass('mobile-on');
    //     }
    // });

};

const setBgFromImgSrc = () => {
    const $personImg = $('.person__img');
    $personImg.addClass('img-bg');
    // $personImg.parent().addClass('a-bg');

    $('.img-bg').each((i, el) => {
        const $el = $(el);
        const src = $el.attr('src');
        const parent = $el.parent();
        parent.css({'background-image': `url(${src})`})
    })
};

const changeProductQuantity = () => {
    $doc.on('click', '.decinc .product__quantity', (e) => {
        const $hiddenCount = $('#hiddenCount');
        const $this = $(e.currentTarget);
        const numparent = $this.parent();
        const numholder = $this.siblings('input');
        let num = numholder.val();

        if ($this.hasClass('inc')) {
            num++;
            num = Math.min(num, parseInt(numparent.attr("data-max-value")));
            numholder.val(num).change();
        }
        else if ($this.hasClass('dec')) {
            num--;
            (num < 1) ? num = 1 : numholder.val(num).change();
            // num = 1;
        }
        if($hiddenCount.length) {
            $hiddenCount.val(num);
        }
        return false
    });
};

const toggleInnerCategoryMenu = () => {
    const catTtitle = $('.category__title');
    if (!$('body').hasClass('home')) {
        catTtitle.addClass('mobile close');
    }


    $doc.on('click', 'body:not(".home") .row:not(.article-category__list) .category__title', (e) => {
        const $el = $(e.currentTarget);
        const winWidth = $win.width();
        if (winWidth < 1099) {
            if ($el.hasClass('close')) {
                $el.removeClass('close').addClass('open');
                $el.next().slideDown()
            } else {
                $el.removeClass('open').addClass('close');
                $el.next().slideUp()
            }
        }
    })

};

const checkEmptyFields = () => {
    $('.checkValue').on('keyup', (e) => {
        const $el = $(e.currentTarget);
        if ($el.val()) {
            $el.addClass('notEmpty')
        } else {
            $el.removeClass('notEmpty')
        }
    })
};


const buildLangSwitcher = () => {
    var switchParent = $('#switcher-parent');
    var html = switchParent.html();
    switchParent.remove();

    $(`#switch-lang`).popover({
        html: true,
        content: html,
        placement: 'bottom',
        template: `<div class="popover popover__langSwitcher" role="tooltip"><div class="arrow"></div><h3 class="popover-title"></h3><div class="popover-content"></div></div>`
    })

};

const menuMobileDropdown = () => {
    $('.main__menu-list > li.menu-item-has-children').each((i,el)=>{
      const $button = $('<span></span>');
      $button.addClass('main__menu-dropdown fa fa-caret-right');
      $(el).append($button);
    });

    $doc.on('click', '.main__menu-dropdown', (e)=>{
        const $target = $(e.currentTarget);
        $target.closest('li').toggleClass('visible');
    })

};

const removed = () => {
    $('.removed').remove();
};

const readsAppend = () => {
    const $parentBox = $('.article-container');
    const $reads = $('.reads__count');
    const $shareBox = $('.addtoany_share_save_container');


    if($reads.length && $shareBox.length) {
        $parentBox.each(function(i,el){
            const $el = $(el);
            const cloned = $el.find('.reads__count').clone().show();
            $el.find('.addtoany_share_save_container').append(cloned);
        });
    }

};

const styleCrutch = () => {
  $('.form-submit .submit').addClass('buttonDark smallText');
};


const loaderInit = () => {

    var c = {
        start: function() {
            $("body").addClass("loader loader_rotate")
        },
        stop: function() {
            return $("body").removeClass("loader"),
                setTimeout(function() {
                    $("body").removeClass("loader_rotate")
                }, 500),
                !1
        },
        toggle: function(n) {
            return e.stop(),
            n && "undefined" != n && n(),
                !1
        }
    };
    window.loader = c

};



const submitMainForms = () => {
    var $submitButton = $('.js-form-submit');
    var thanxText = $('#thanxText').val();
    $submitButton.click(function (e) {
        var $form = $(e.currentTarget).closest('form');
        var emailForSend = $form.data('email');
        if ($form[0].checkValidity()) {
            var arrayOfData = $form.serializeArray();
            var dataForSend = {};

            arrayOfData.forEach(function(el){
                dataForSend[el.name] = el.value;
            });

            if(emailForSend) dataForSend.for_send = emailForSend;

            $.ajax({
                method: 'post',
                url: window.myajax.url,
                data: {
                    action: 'form_handler',
                    data: dataForSend
                },
                success: function (res) {
                    $form
                        .find('input:not([type="hidden"]), textarea:not([type="hidden"])')
                        .removeClass('notEmpty')
                        .val('');
                    alert(thanxText);
                },
                error: function () {
                    alert('Произошла ошибка, попробуйте пожалуйста позже!')
                }
            });
            return false;
        }
    });
};

const removeEmpty = () => {
    const $content = $('.article-text__text');
    $content.find('p').each(function(i,el){
        const $el = $(el);
        const text = $el.text().trim();
        if(!text && !$el.children().length) {
            $el.remove();
        }
    });
};

const magnificPopupAndZoomerInit = () => {
    const winWidth = $(window).width();
    const $productImg = $(".article-text__text img:not(.product__img)");
    const magnificGalleryProps = {
        type: 'image',
        closeOnContentClick: true,
        mainClass: 'mfp-img-mobile',
        src: $(this).attr('linkTo'),
        image: {
            verticalFit: true
        },
        gallery: {
            enabled:true
        }
    };

    $productImg.each(function(i,el){
        const $el = $(el);
        const src = $el.attr('src');
        $el.wrap('<span class="product-image__popup inline-block" href="'+src+'"></span>');
        $el.closest('p').addClass('product-image__wrapped inline-block')
    });

    /*winWidth>991*/
    if(false) {
        $('.product-image__popup').magnificPopup(magnificGalleryProps);
    } else {
        const $imageViewerImages = $('.product-image__popup img');
        const viewer = ImageViewer();

        $imageViewerImages.each((i,el)=>{
            const $img = $(el);
            const src = $img.attr('src');
            $img.attr('data-high-res-src',src)

        });

        $imageViewerImages.on('click', function () {
            viewer.show(this.src, $(this).data('high-res-img'));
        });
    }

    $('.photo-block__pic,.product-thumbnail')
        .on('click', function(e){return false})
        .magnificPopup(magnificGalleryProps);

    $('.with-thumbnail').each(function() { // the containers for all your galleries
        $(this).magnificPopup({
            delegate: 'p', // the selector for gallery item
            type: 'image',
            gallery: {
                enabled:true
            }
        });
    });
};

const submitForm = () => {
    $(document).on('click', '.js-submit', function(e){
            e.preventDefault();
            $(this).closest('form').submit();
        })
};

const owlCarouselInit = () => {
    $('.owl-carousel').owlCarousel({
        nav: true,
        autoWidth: true
    });
};

const smoothScrollToAnchor = () => {
    $('.products__item .products__buy').on('click', function(e) {
        const href = $(this).attr('href');
        e.preventDefault();
        localStorage.setItem('formAnchor', '1');
        window.location = href
    });

    const formAnchor = localStorage.getItem('formAnchor');
    const $form = $('.contact__form');

    if(formAnchor && $form.length) {
        $('html, body').animate({
            scrollTop: $form.offset().top - 25
        }, 300);

        localStorage.removeItem('formAnchor');
    }
};

const dragProductsVideo = () => {
    /*eto pipec*/
    const $quanBox = $('.product__quantity-box');
    const $share = $('.addtoany_share_save_container');
    const $box = ($share.length) ? $share : $quanBox;
    const $video = $('#videoContainer').clone(true).removeClass('hidden-xs hidden-sm').addClass('hidden-md hidden-lg');
    if($video.length) {
        if($box && $box.length) {
            // console.log('$box', $box)
            $box.append($video);
        } else {
            $quanBox.prepend($video)
        }
    }
};

const disabledCopy = ()=>{
    const isLogged = $('body').hasClass('logged-in');
    $('body')
        .on('copy paste cut drag drop', function (e) {
            if(!isLogged) e.preventDefault();
        })
        .on('contextmenu', (e)=>{
            if(!isLogged) e.preventDefault();
        });
};

const removeFixWidthTable = ()=>{
    const $table = $('table');
    const remover = ($el) =>{
        $el.removeAttr('width style');
        $el.find('td,th').removeAttr('width style')
    };

    $table.each(function(i,el){
        const $el = $(el);
        remover($el)
    });

    $('.article-text__text table').css("width", "100%");
};

const initSuxAccordion = () =>{
    $('.accordion-container').find('br').remove();
    $(".set > a").on("click", function(){
        const $this = $(this);
        const $content = $this.siblings('.content');
        if($this.hasClass('active')){
            $this.removeClass("active");
            $content.slideUp(200);
            $(".set > a i").removeClass("fa-minus").addClass("fa-plus");
        }else{
            $(".set > a i").removeClass("fa-minus").addClass("fa-plus");
            $this.find("i").removeClass("fa-plus").addClass("fa-minus");
            $(".set > a").removeClass("active");
            $this.addClass("active");
            $('.content').slideUp(200);
            $content.slideDown(200);
        }
    });
};

$doc.on('ready', () => {
    removeFixWidthTable();
    disabledCopy();
    smoothScrollToAnchor();
    initDotDotDot();
    submitMainForms();
    alertForDisableLinks();
    changeProductQuantity();
    setBgFromImgSrc();
    toggleMobileMenu();
    toggleInnerCategoryMenu();
    checkEmptyFields();
    buildLangSwitcher();
    menuMobileDropdown();
    readsAppend();
    styleCrutch();
    loaderInit();
    submitForm();
    magnificPopupAndZoomerInit();
    owlCarouselInit();
    dragProductsVideo();
    initSuxAccordion();
});



$win.on('resize', () => {
    const winWidth = $win.width();

    if (winWidth > 1099) {
        $('body').removeClass('mobile-on');
        $('#btnMobile .burger').removeClass('active');
        $('.main__menu-list > li').removeClass('visible');
    }
}).on('keydown', (e)=>{
    const isLogged = $('body').hasClass('logged-in');
    if(e.keyCode === 123 && !isLogged) {
        e.preventDefault();
    }
}).on('mousedown', (e)=>{
    const isLogged = $('body').hasClass('logged-in');
    if(e.button === 2 && !isLogged) {
        e.preventDefault();
        return false;
    }
});